import { createApi } from '@reduxjs/toolkit/query/react'
import { RouteSchema } from 'APIzod'
import { client } from 'graphql/client'
import { z } from 'zod'
import { listRoutes } from 'graphql/queries'
import { Route } from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { errorHandler } from './errors'

export const routesApi = createApi({
  reducerPath: 'routes',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    listRoutes: builder.query<Route[], string>({
      queryFn: async () => {
        try {
          const { data } = await client.graphql({ query: listRoutes })
          return { data: z.array(RouteSchema).parse(data.listRoutes) }
        } catch (error) {
          throw errorHandler('Unexpected error occurred while fetching the routes', error)
        }
      }
    })
  })
})

export const { useLazyListRoutesQuery, useListRoutesQuery } = routesApi
