import { combineReducers } from '@reduxjs/toolkit'
import tokenHandlingReducer from './tokenHandling/tokenSlice'
import appReducer from './slices/appSlice'
import mrmReducer from './slices/mrmSlice'
import startMissionReducer from './slices/startMissionSlice'
import cancelMissionReducer from './slices/cancelMissionSlice'
import archiveMissionReducer from './slices/archiveMissionSlice'
import resetMissionReducer from './slices/resetMissionSlice'
import assignMissionReducer from './slices/assignMissionSlice'
import configReducer from './setup/configSlice'
import langReducer from './i18n/langSlice'
import { hubsApi } from '../services/hubs'
import { routesApi } from '../services/routes'
import { vehiclesApi } from '../services/vehicles'
import { userApi } from '../services/user'
import { missionApi } from '../services/mission'
import { reverseGeocodeApi } from 'services/reverseGeocode'
import { commandApi } from '../services/command'

export const rootReducer = combineReducers({
  config: configReducer,
  lang: langReducer,
  app: appReducer,
  mrm: mrmReducer,
  startMission: startMissionReducer,
  cancelMission: cancelMissionReducer,
  archiveMission: archiveMissionReducer,
  resetMission: resetMissionReducer,
  assignMission: assignMissionReducer,
  tokenHandling: tokenHandlingReducer,
  // RTK Query ApiSlices
  user: userApi.reducer,
  hubs: hubsApi.reducer,
  routes: routesApi.reducer,
  vehicles: vehiclesApi.reducer,
  mission: missionApi.reducer,
  reverseGeocode: reverseGeocodeApi.reducer,
  command: commandApi.reducer
})

export type RootState = ReturnType<typeof rootReducer>
